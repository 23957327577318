import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import img from "../images/background.jpg";

const IndexPage = () => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let bodyFormData = new FormData();

    bodyFormData.set("name", data.name);
    bodyFormData.set("email", data.email);
    bodyFormData.set("text", "Test");

    setLoadingRequest(true);
    axios
      .post(
        `https://api.whitebrim.co/api/project/592d4cf5b549b452a450d829/account/email`,
        bodyFormData
      )
      .then((response) => {
        setLoadingRequest(false);
        setRequestMessage("Success");
        setTimeout(() => {
          setRequestMessage("");
        }, 2500);
      })
      .catch((err) => {
        console.log(err.response);
        formError();
      });
  };

  const formError = () => {
    setLoadingRequest(false);
    setRequestMessage("Error");
    setTimeout(() => {
      setRequestMessage("");
    }, 2500);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div className="max-w-lg w-full space-y-8 bg-white shadow-lg rounded-lg p-5">
        <div>
          <p className="mt-2 text-center text-sm text-tongio font-poppins">
            REQUEST A CALL BACK
          </p>
          <h2
            className="mt-6 ml-auto mr-auto text-center text-3xl font-bold text-coal font-poppins"
            style={{ maxWidth: 400 }}
          >
            NEED PROFESSIONAL PROPERTY MANAGEMENT SERVICES ?
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              className="text-gray-400 appearance-none relative block w-full p-4 rounded border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-tongio focus:border-tongio focus:z-10 sm:text-sm"
              placeholder="Your name"
              {...register("name", { required: true })}
            />
            {errors && errors.name && (
              <span className="form-error ml-1">Required Field *</span>
            )}
          </div>
          <div>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="text-gray-400 appearance-none relative block w-full p-4 rounded border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-tongio focus:border-tongio focus:z-10 sm:text-sm"
              placeholder="Your email address"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
              })}
            />
            {errors && errors.email && (
              <span className="form-error ml-1">Required Field *</span>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="font-poppins font-light relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-tongio hover:bg-tongio focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tongio"
              style={{
                fontSize: 13,
                paddingTop: 13,
                paddingBottom: 13,
                paddingLeft: 32,
                paddingRight: 32,
                letterSpacing: 1,
              }}
            >
              {loadingRequest ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : requestMessage !== "" ? (
                requestMessage
              ) : (
                "REQUEST A CALL SCHEDULE"
              )}
            </button>
          </div>
          <div className="ml-auto mr-auto" style={{ maxWidth: 400 }}>
            <p className="mt-2 text-center text-xs text-gray-400 leading-6">
              We are committed to protecting your privacy. We will never collect
              information about you without your explicit consent.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IndexPage;
